@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-orange: #ff6f42;
  --color-black: #181818;
  --color-black-without-opacity: #181818;
  --color-dark: #121212;
  --color-dark-grey: #545454;
  --color-dark-grey-half-opacity: rgba(224, 226, 222, 0.5);
  --color-mid-grey: #8e8e93;
  --color-soft-grey: #e0e2de;
  --color-off-white: #f9f9f9;
  --color-white: #ffffff;
  --bg-color-overlay: rgba(249, 249, 249, 0.9);
  --body-bg-color: #f9f9f9;
  --article-top-bg-color: var(--color-soft-grey);
}

@font-face {
  font-family: MaxFett;
  font-weight: 900;
  font-style: normal;
  src: url("/fonts/MaxFett/MaxFett-Black.woff2") format("woff2");
}

@font-face {
  font-family: ABCSocial;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("/fonts/ABCSocial/ABCSocial-Regular.woff2") format("woff2");
  font-stretch: normal;
}
@font-face {
  font-family: ABCSocial;
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url("/fonts/ABCSocial/ABCSocial-Medium.woff2") format("woff2");
  font-stretch: normal;
}
@font-face {
  font-family: ABCSocial;
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("/fonts/ABCSocial/ABCSocial-Bold.woff2") format("woff2");
  font-stretch: normal;
}

@media screen and (max-width: 768px) {
  .hide-in-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .hide-in-desktop {
    display: none !important;
  }
}

.footer {
  /* margin-top: 200px; */
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: var(--color-orange);
}

@media screen and (max-width: 768px) {
  .footer {
    /* margin-top: 50px; */
    padding: 25px 20px;
    align-items: stretch;
    justify-content: normal;
    gap: 30px;
  }
}

.footer__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}

@media screen and (max-width: 768px) {
  .footer__left {
    gap: 150px;
    padding-bottom: 35px;
  }
}

.footer__left-top,
.footer__right {
  display: flex;
  gap: 80px;
}

.footer__right {
  flex-direction: column;
  border-left: 1px solid #181818;
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  .footer__right {
    justify-content: space-between;
    border-left: none;
    padding-left: 0;
    gap: 30px;
  }

  .footer__right > a {
    margin-top: auto;
  }
}

.footer__right a {
  display: flex;
  align-items: flex-end;
}

.footer .logo {
  max-width: 100%;
  height: auto;
}

.footer .caption {
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #181818;
}

@media screen and (max-width: 768px) {
  .footer .caption {
    font-size: 10px;
    line-height: 12px;
  }
}

.footer__left-bottom {
  display: flex;
}

.footer__social-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 5px;
}

.footer__social-links svg {
  height: 25px;
}

.footer__social-links svg:hover path {
  fill: #f9f9f9;
}

.footer__menu {
  padding: 0;
  margin: 0;
  list-style: none;
  column-count: 2;
}

@media screen and (max-width: 768px) {
  .footer__menu {
    column-count: 1;
  }
}

.footer__menu li a {
  color: #181818;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .footer__menu li a {
    font-size: 20px;
    line-height: 24px;
  }
}

.footer__menu li a:hover {
  color: #f9f9f9;
}

.caption {
  font-family: "DM Mono", monospace;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

/* https://github.com/vercel/next.js/issues/18915#issuecomment-1005128405 */
.image-block-hack > span {
  display: block !important;
}

.common {
  background-image: url("/img/bg/common.jpeg");
  background-color: #6ed550;
  /* background-image: url("https://static.vecteezy.com/system/resources/previews/001/874/917/non_2x/light-green-template-with-sky-clouds-vector.jpg");
  background-color: #99db88;
  background-blend-mode: multiply; */
  /* background-image: url("https://static.vecteezy.com/system/resources/previews/002/626/202/non_2x/light-green-background-with-colorful-stars-colorful-illustration-with-abstract-gradient-stars-design-for-your-business-promotion-vector.jpg"); */
}
.common-flat {
  background-color: #6ed550;
}

.unique {
  background-image: url("/img/bg/space.jpg");
  /* background-color: #560fbd; */
  background-color: #21175e;
}
.unique-flat {
  background-color: #21175e;
}

.legendary {
  background-image: url("/img/bg/legendary.jpeg");
  background-color: #e050de;
  /* background-image: url("https://static.vecteezy.com/system/resources/previews/013/437/502/non_2x/light-purple-pink-pattern-with-abstract-stars-vector.jpg");
  background-color: #c46ef5; */
}
.legendary-flat {
  background-color: #e050de;
}

.epic {
  background-image: url("/img/bg/epic.jpeg");
  /* background-image: url("https://static.vecteezy.com/system/resources/previews/013/655/508/non_2x/light-blue-background-with-small-and-big-stars-vector.jpg"); */
  background-color: #08d8fe;
}
.epic-flat {
  background-color: #08d8fe;
}

.rare {
  background-image: url("/img/bg/rare.jpeg");
  /* background-image: url("https://static.vecteezy.com/system/resources/previews/003/214/844/non_2x/light-orange-background-with-colorful-stars-vector.jpg"); */
  background-color: #fdd301;
}
.rare-flat {
  background-color: #fdd301;
}
